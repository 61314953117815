import React from "react";
import {
  SEO,
  PageLayout,
  Content,
  Image,
  Background,
  PaginatedGrid,
  componentIterator,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"about-page"}>
          <Grid
            stackable={true}
            doubling={true}
            columns={2}
            className={"about-grid"}
          >
            <Grid.Column className={"content-column"}>
              <Content className={"about-content"} textAlign={"left"}>
                <Header as={"h1"} className={"about-us-header"}>
                  ABOUT US
                </Header>
                <Content.Markup
                  width={16}
                  isMarkdown={false}
                  content={
                    <div>
                      <p>
                        Poke Cleveland is a fast casual restaurant in Highland
                        Heights, Ohio serving high quality ingredients.
                      </p>
                      <p>
                        We deal with locally sourced sushi grade tuna and
                        salmon, delivered multiple times a week to ensure
                        freshness. We also serve pre cooked options like shrimp,
                        grilled chicken, and crabstick. We carry tofu as well as
                        a large selection of fruits and vegetables. Poke is not
                        only delicious and filling, but you are not weighted
                        down and have great energy for the rest of your day!
                      </p>
                      <p>
                        Poke is a traditional Hawaiian dish dating back
                        centuries. Sailors would bring in their catch and finely
                        cut and cube. The villagers would gather together with
                        items they grew on their farms, make a big pot of rice,
                        and enjoy a feast together. Poke was a very communal
                        meal.
                      </p>
                      <p>
                        Plenty of Pacific nations do some form of raw fish,
                        served with veggies, and covered in sauce. The
                        Philippines has Kinilaw, they do Ceviche in Peru, Korea
                        has Hoedeopbap, and of course Japan has Sashimi. What
                        separates most regions will be the flavor profile. We
                        have a heavy soy sauce influence, as well as a sweeter
                        take on most. We have a few soy sauces, we have a few
                        with some heat, and definitely a couple that are nice
                        and sweet.
                      </p>
                      <p>
                        The create your own assembly line style of our
                        restaurant leaves with a multitude of flavor pairings
                        and possibilities. Come on in and give it a try! Even
                        the most hesitant people fall in love with Poke once
                        they try it.
                      </p>
                    </div>
                  }
                />
              </Content>
            </Grid.Column>
            <Grid.Column className={"image-column"}>
              <Image
                className={"image"}
                src={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                  numToSelect: 1,
                })}
              />
            </Grid.Column>
          </Grid>
          <div className={"image-gallery-container"}>
            <Background
              columns={1}
              stackable={true}
              className={"images-background"}
            >
              <Background.Pattern type={"CIRCLE"}>
                <PaginatedGrid
                  rows={2}
                  columns={4}
                  className={"image-gallery-grid"}
                  mobile={{ columns: 2, rows: 4, stackable: false }}
                  items={componentIterator({
                    iterator: getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "ImageCollection",
                    }),
                    component: <Image background={true} className={"image"} />,
                    propMap: { src: "__all__" },
                  })}
                />
              </Background.Pattern>
            </Background>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
